// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aims-debrief-ul {
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 24px;
}

.aims-debrief-ul li {
    list-style: disc;
    margin-bottom: 10px;
    font-size: 14px;
}

.aims-debrief-tr {
    position: relative;
}

.aims-continue {
    display: flex;
    justify-content: end;
}

.aims-debrief-radio-label {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/message-templates/debrief.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,MAAM;IACN,OAAO;IACP,SAAS;AACb","sourcesContent":[".aims-debrief-ul {\n    padding-left: 20px;\n    margin-top: 10px;\n    margin-bottom: 24px;\n}\n\n.aims-debrief-ul li {\n    list-style: disc;\n    margin-bottom: 10px;\n    font-size: 14px;\n}\n\n.aims-debrief-tr {\n    position: relative;\n}\n\n.aims-continue {\n    display: flex;\n    justify-content: end;\n}\n\n.aims-debrief-radio-label {\n    position: absolute;\n    display: block;\n    width: 100%;\n    top: 0;\n    left: 0;\n    bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
