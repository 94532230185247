import React, { useState } from 'react'
import { CampfireEnterpriseHeader, CampfireEnterpriseButton, CampfireEnterpriseText, CampfireEnterpriseTypeahead } from '@etrade-design-language/campfire-enterprise-react';
import { tasks } from '../mock-data';
import '../style/tasks.css'

export default function TasksPanel({ summarizeClick, debriefClick, drafterClick, guruClick, museClick, contentGroup }) {
    const [filteredTasks, setFilteredTasks] = useState(tasks);

    const filterTasks = (e) => {
        const enteredText = e?.target?.value?.toLowerCase();
        if (!enteredText) return setFilteredTasks(tasks);
        const someTasks = tasks.filter(task => {
            return task.task.toLowerCase().includes(enteredText) || task.description.toLowerCase().includes(enteredText)
        })
        setFilteredTasks(someTasks)
    };

    const taskClick = (task) => {
        if (task === 'Summarizer') {
            summarizeClick();
        } else if (task === 'Debrief') {
            debriefClick();
        } else if (task === 'Email Wizard') {
            drafterClick();
        } else if (task === 'Prompt Guru') {
            guruClick();
        } else if (task === 'Marketing Muse') {
            museClick();
        }
    };

    return (
        <div className="aims-tasks">
            <CampfireEnterpriseHeader
                headingLevel="2"
                variant="section"
                verticalOffset="md"
            >All Workflows</CampfireEnterpriseHeader>
            <CampfireEnterpriseTypeahead
                colAlign="left"
                placeholder="Filter Workflows"
                verticalOffset="md"
                onSearchInput={filterTasks}
            />
            <ul>
                {filteredTasks.map((t, index) => (<li key={index} className="aims-task">
                    <CampfireEnterpriseButton
                        buttonId="button-id"
                        buttonType="button"
                        size="default"
                        type="inline-link"
                        verticalOffset="clear"
                        onButtonClick={() => taskClick(t.task)}
                    > {(contentGroup === 'b' && t.taskNameAlt) ? t.taskNameAlt : t.task}
                    </CampfireEnterpriseButton>
                    <CampfireEnterpriseText>
                        {t.description}
                    </CampfireEnterpriseText>
                </li>
                ))}
                {!(filteredTasks.length > 0) && <div className='aims-no-matching-tasks'>No matching workflows</div>}
            </ul>
        </div>
    )
} 