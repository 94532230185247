import React from 'react'
import { CampfireEnterpriseExpandCollapse, CampfireEnterpriseButton, CampfireEnterpriseIcon } from '@etrade-design-language/campfire-enterprise-react';

export default function Summary({addMessage}) {
    const statusUpdateHandler = e => console.log(e.detail);

    return (
        <div>
            <p className="aims-p">
                A just transition to a low-carbon economy, for instance, will require equitable distribution of the economic benefits of that transition. By 2030, the net-zero transition has the potential to create 30 million jobs in clean energy, efficiency solutions and low-emissions technologies.2 Public and private investments will be needed to make sure that the benefits of the transition are widely available to everyone, especially communities historically left out of new economic opportunities. “Investors are asking: How do we drive capital towards the low carbon-transition in a more inclusive and equitable manner,” Alsford says. This could include investments in skills training for jobs installing solar panels, retrofitting commercial buildings or even manufacturing electric vehicle batteries.
            </p>

            <p className="aims-p">
                Biodiversity loss will also be top of mind for many investors this year, Alsford says. “The recent global agreement at COP15 in late 2022 has certainly sent a signal on the need for greater funding flows toward conservation and nature,” she says.    Leaders across public and private sectors are realizing that healthy ecosystems are vital to tackling climate change, because they help remove carbon or offer protection against extreme weather events such as flooding. Biodiversity is also essential to the economy, as more than half of the world’s GDP is dependent on nature and its services,3 such as pollination and water quality, and the looming ecosystem crisis could have dire impacts on industries.
            </p>

            <p className="aims-p"></p>

            <CampfireEnterpriseExpandCollapse
                expanded
                headingLevel="3"
                triggerText="Send a follow-up message"
                className="aims-followup"
                onStatusUpdate={statusUpdateHandler}
            >
                <ul className="aims-followup-ul" aria-label="Send a follow-up message">
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            buttonType="button"
                            onClick={() => addMessage({ content: 'Draft an email to my colleague about low-carbon solutions', isBot: false })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Draft an email to my colleague about low-carbon solutions
                        </CampfireEnterpriseButton>
                    </li>
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            onClick={() => addMessage({ content: 'How does biodiversity loss impact the economy?', isBot: false })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            How does biodiversity loss impact the economy?
                        </CampfireEnterpriseButton>
                    </li>
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            onClick={() => addMessage({ content: 'Translate this summary into French', isBot: false })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Translate this summary into French
                        </CampfireEnterpriseButton>
                    </li>
                </ul>
            </CampfireEnterpriseExpandCollapse>

            <CampfireEnterpriseExpandCollapse
                headingLevel="3"
                triggerText="Sources"
                className="aims-followup"
                onStatusUpdate={statusUpdateHandler}
            >
                <ul className="aims-sources-ul" aria-label="Sources">
                    <li className="aims-sources-li">
                        <div>
                            <sup>1</sup>
                            &nbsp;
                            <CampfireEnterpriseButton
                                type="inline-link"
                                buttonType="button"
                                onClick={() => addMessage({ content: 'Draft an email to my colleague about low-carbon solutions', isBot: false })}
                            >

                                US-policy_Energy.pdf
                            </CampfireEnterpriseButton>
                            &nbsp;
                            (2022), p. 1
                        </div>
                        <div>
                            June 1, 2022
                        </div>
                    </li>
                    <li className="aims-sources-li">
                        <div>
                            <sup>2</sup>
                            &nbsp;
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({ content: 'How does biodiversity loss impact the economy?', isBot: false })}
                            >
                                US-policy_Energy.pdf
                            </CampfireEnterpriseButton>
                            &nbsp;
                            (2022), p. 6
                        </div>
                        <div>
                            June 1, 2022
                        </div>
                    </li>
                </ul>
            </CampfireEnterpriseExpandCollapse>
        </div>
    )
}