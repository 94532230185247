// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aims-drafter-ul {
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.aims-drafter-ul li {
    list-style: disc;
    margin-bottom: 5px;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/message-templates/drafter.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".aims-drafter-ul {\n    padding-left: 20px;\n    margin-top: 10px;\n    margin-bottom: 20px;\n}\n\n.aims-drafter-ul li {\n    list-style: disc;\n    margin-bottom: 5px;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
