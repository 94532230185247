import React from 'react'
import { CampfireEnterpriseExpandCollapse, CampfireEnterpriseButton, CampfireEnterpriseIcon } from '@etrade-design-language/campfire-enterprise-react';

export default function Guru({ addMessage }) {
    const statusUpdateHandler = e => console.log(e.detail);

    return (
        <div>
            <p className="aims-p">
                I can help you create a structured prompt using instructions provided.
            </p>

            <p className="aims-p"></p>

            <CampfireEnterpriseExpandCollapse
                expanded
                headingLevel="3"
                triggerText="Send a follow-up message"
                className="aims-followup"
                onStatusUpdate={statusUpdateHandler}
            >
                <ul className="aims-followup-ul" aria-label="Send a follow-up message">
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            buttonType="button"
                            onClick={() => addMessage({ content: 'Lorem ipsum doalr septa nueva', isBot: false })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Lorem ipsum doalr septa nueva
                        </CampfireEnterpriseButton>
                    </li>
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            buttonType="button"
                            onClick={() => addMessage({ content: 'Lorem ipsum doalr septa nueva', isBot: false })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Lorem ipsum doalr septa nueva
                        </CampfireEnterpriseButton>
                    </li>
                </ul>
            </CampfireEnterpriseExpandCollapse>
        </div>
    )
}