import React from 'react'
import { CampfireEnterpriseExpandCollapse, CampfireEnterpriseButton, CampfireEnterpriseIcon } from '@etrade-design-language/campfire-enterprise-react';

export default function Summary(addMessage) {
    const statusUpdateHandler = e => console.log(e.detail);

    return (
        <div>
            <ul className="aims-meeting-summary-ul">
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.</li>
                <li>Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </li>
            </ul>

            <p className="aims-p"></p>

            <CampfireEnterpriseExpandCollapse
                expanded
                headingLevel="3"
                triggerText="Send a follow-up message"
                className="aims-followup"
                onStatusUpdate={statusUpdateHandler}
            >
                <ul className="aims-followup-ul" aria-label="Send a follow-up message">
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            buttonType="button"
                            onClick={() => addMessage({ content: 'Draft an email in Outlook capturing the meeting notes', isBot: false})}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Draft an email in Outlook capturing the meeting notes
                        </CampfireEnterpriseButton>
                    </li>
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            onClick={() => addMessage({ content: 'Translate the meeting notes to French', isBot: false})}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Translate the meeting notes to French
                        </CampfireEnterpriseButton>
                    </li>
                </ul>
            </CampfireEnterpriseExpandCollapse>

            <CampfireEnterpriseExpandCollapse
                headingLevel="3"
                triggerText="Sources"
                className="aims-followup"
                onStatusUpdate={statusUpdateHandler}
            >
                <ul className="aims-sources-ul" aria-label="Sources">
                    <li className="aims-sources-li">
                        <div>
                            <sup>1</sup>
                            &nbsp;
                            <CampfireEnterpriseButton
                                type="inline-link"
                                buttonType="button"
                                onClick={() => addMessage({content: 'Draft an email to my colleague about low-carbon solutions', isBot: false})}
                            >

                                US-policy_Energy.pdf
                            </CampfireEnterpriseButton>
                            &nbsp;
                            (2022), p. 1
                        </div>
                        <div>
                            June 1, 2022
                        </div>
                    </li>
                    <li className="aims-sources-li">
                        <div>
                            <sup>2</sup>
                            &nbsp;
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({content: 'How does biodiversity loss impact the economy?', isBot: false})}
                            >
                                US-policy_Energy.pdf
                            </CampfireEnterpriseButton>
                            &nbsp;
                            (2022), p. 6
                        </div>
                        <div>
                            June 1, 2022
                        </div>
                    </li>
                </ul>
            </CampfireEnterpriseExpandCollapse>
        </div>
    )
}