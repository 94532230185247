// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aims-summary-radio {
    margin-top: 16px;
}

.aims-summary-dropdowns {
    display: flex;
    gap: 16px;
    margin: 16px 0 16px;
}

.aims-summary-dropdowns div {
    flex-basis: 33%;
}

.aims-summary-upload {
    margin-top: 12px;
    margin-bottom: 12px;
}

.aims-summary-submit {
    display: flex;
    justify-content: flex-end;
}

.aims-space-between {
    display: flex;
    justify-content: space-between;
}

.aims-success {
    color: green;
    font-size: 16px;
}

.aims-file-name {
    width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/components/message-templates/upload-summarize.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".aims-summary-radio {\n    margin-top: 16px;\n}\n\n.aims-summary-dropdowns {\n    display: flex;\n    gap: 16px;\n    margin: 16px 0 16px;\n}\n\n.aims-summary-dropdowns div {\n    flex-basis: 33%;\n}\n\n.aims-summary-upload {\n    margin-top: 12px;\n    margin-bottom: 12px;\n}\n\n.aims-summary-submit {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.aims-space-between {\n    display: flex;\n    justify-content: space-between;\n}\n\n.aims-success {\n    color: green;\n    font-size: 16px;\n}\n\n.aims-file-name {\n    width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
