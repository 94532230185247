// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aims-history-tab-contents {
    padding-top: 16px;
}

.aims-history-heading {
    margin-top: 8px;
}

.aims-history-ul {
    padding: 8px 0 24px;
}

.aims-history-li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E2E2E2;
    padding: 8px 0;
    align-items: center;
}

.aims-history-li button {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/style/history.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gCAAgC;IAChC,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".aims-history-tab-contents {\n    padding-top: 16px;\n}\n\n.aims-history-heading {\n    margin-top: 8px;\n}\n\n.aims-history-ul {\n    padding: 8px 0 24px;\n}\n\n.aims-history-li {\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid #E2E2E2;\n    padding: 8px 0;\n    align-items: center;\n}\n\n.aims-history-li button {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
