import Summary from './components/message-templates/summary';


import DebriefDetails from './components/message-templates/debrief-details';
import MeetingSummary from './components/message-templates/meeting-summary';
import UploadSummarize from './components/message-templates/upload-summarize';
import Midyear from './components/message-templates/midyear';
import MidyearEmail from './components/message-templates/midyear-email';


import { answers } from './mock-data';

// returns a message object with mock template or response
export const mockResponse = (lastMessage, addMessage, contentGroup) => {
    // if (lastMessage.includes('How can “Summarizer” assist me?')) {
    //     return { content: <Summarize addMessage={addMessage} />, isBot: true, author: 'Summarizer' };
    // }
    // else if (lastMessage.includes('How can the Summarize workflow assist me?')) {
    //     return { content: <Summarize addMessage={addMessage} />, isBot: true, author: 'Summarize Content' };
    // }
    if (lastMessage.includes('Summarize the attached PDFs')) {
        if (contentGroup === 'b') {
            return { content: <UploadSummarize addMessage={addMessage} />, isBot: true, author: 'Summarize Content' };
        } else {
            return { content: <UploadSummarize addMessage={addMessage} />, isBot: true, author: 'Summarizer' };
        }
    }
    // else if (lastMessage.includes('Wizard')) {
    //     return { content: <Drafter addMessage={addMessage} />, isBot: true, author: 'Email Wizard' };
    // }
    // else if (lastMessage.includes('How can the Email workflow assist me?')) {
    //     return { content: <Drafter addMessage={addMessage} />, isBot: true, author: 'Write an email' };
    // }
    else if (lastMessage.includes('that I am out sick')) {
        if (contentGroup === 'b') {
            return { content: <Midyear addMessage={addMessage} />, isBot: true, author: 'Write an email' };
        } else {
            return { content: <Midyear addMessage={addMessage} />, isBot: true, author: 'Email Wizard' };
        }
    }
    else if (lastMessage.includes('100 word email in a casual tone to my colleagues.')) {
        if (contentGroup === 'b') {
            return { content: <MidyearEmail addMessage={addMessage} />, isBot: true, author: 'Write an email' };
        } else {
            return { content: <MidyearEmail addMessage={addMessage} />, isBot: true, author: 'Email Wizard' };
        }
    }
    // else if (lastMessage.includes('How can the Meeting Notes workflow assist me?')) {
    //     return { content: <Debrief addMessage={addMessage} />, isBot: true, author: 'Capture meeting notes' };
    // }
    // else if (lastMessage.includes('How can "Debrief" assist me?')) {
    //     return { content: <Debrief addMessage={addMessage} />, isBot: true, author: 'Debrief' };
    // }
    else if (lastMessage.includes('Summarize the following recording')) {
        if (contentGroup === 'b') {
            return { content: <DebriefDetails addMessage={addMessage} />, isBot: true, author: 'Capture meeting notes' };
        } else {
            return { content: <DebriefDetails addMessage={addMessage} />, isBot: true, author: 'Debrief' };
        }
    }
    else if (lastMessage.includes('summar')) {
        if (contentGroup === 'b') {
            return { content: <UploadSummarize addMessage={addMessage} />, isBot: true, author: 'Summarize Content' };
        } else {
            return { content: <UploadSummarize addMessage={addMessage} />, isBot: true, author: 'Summarizer' };
        }
    }
    else if (lastMessage.includes('meeting')) {
        if (contentGroup === 'b') {
            return { content: <MeetingSummary addMessage={addMessage} />, isBot: true, author: 'Capture meeting notes', feedbackFooter: true };
        } else {
            return { content: <MeetingSummary addMessage={addMessage} />, isBot: true, author: 'Debrief', feedbackFooter: true };
        }

    } else if (lastMessage.includes('2000 words')) {
        if (contentGroup === 'b') {
            return {
                content: <Summary addMessage={addMessage} />, isBot: true, author: 'Summarize Content', feedbackFooter: true
            }
        } else {
            return {
                content: <Summary addMessage={addMessage} />, isBot: true, author: 'Summarizer', feedbackFooter: true
            }
        }
    // } else if (lastMessage.includes('How can "Prompt Guru" assist me')) {
    //     return { content: <Guru addMessage={addMessage} />, isBot: true, author: 'Prompt Guru' };
    // } else if (lastMessage.includes('How can the Write a prompt workflow assist me?')) {
    //     return { content: <Guru addMessage={addMessage} />, isBot: true, author: 'Write a prompt' }
    // } else if (lastMessage.includes('How can the Marketing content workflow assist me?')) {
    //     return { content: <Muse addMessage={addMessage} />, isBot: true, author: 'Create marketing content' };
    // } else if (lastMessage.includes('How can "Marketing Muse" assist me?')) {
    //     return { content: <Muse addMessage={addMessage} />, isBot: true, author: 'Marketing Muse' };
    } else {
        return { content: answers[Math.floor(Math.random() * answers.length)], isBot: true };
    }
}