import React, { useState } from 'react';
import { CampfireEnterpriseChatMessage, CampfireEnterpriseIcon, CampfireEnterpriseButton, CampfireEnterpriseTagGroup, CampfireEnterpriseTag, CampfireEnterpriseActionMenu } from '@etrade-design-language/campfire-enterprise-react';

export default function ChatBubble({ message, isBot, files, timeStamp, author, feedbackFooter }) {
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);

    const someAction = (action) => {
        console.log('action 🔥', action);
    }

    const actions = [
        {
            // iconLeft: 'icons go here if we need them!',
            text: 'Copy Answer',
            onclick: someAction
        },
        {
            text: 'Ask follow-up',
            onclick: someAction
        },
        {
            text: 'Gmail',
            onclick: someAction
        },
        {
            text: 'Teams',
            onclick: someAction
        },
        {
            text: 'Create JIRA issue',
            onclick: someAction
        },
    ];

    const likeClick = () => {
        setIsLiked(!isLiked);
        setIsDisliked(false);
    };

    const dislikeClick = () => {
        setIsDisliked(!isDisliked);
        setIsLiked(false);
    };

    return (
        <div>
            <CampfireEnterpriseChatMessage
                avatarType={isBot ? 'icon' : 'letter'}
                timestamp={timeStamp}
                className="aims-chat-message"
                type={isBot ? 'incoming' : 'outgoing'}
                username={author || (isBot ? 'AI@MS+' : 'Anna Analyst')}
                verticalOffset="clear"
            >
                {!!isBot && <CampfireEnterpriseIcon slot="avatar">ai</CampfireEnterpriseIcon>}
                {!isBot && <div slot="avatar">A</div>}
                {message}
                {!!files?.length && <CampfireEnterpriseTagGroup>
                    {files.map((file, index) => (<CampfireEnterpriseTag key={`tag-${index}`} status="informational" testId={index}>{file}</CampfireEnterpriseTag>))}
                </CampfireEnterpriseTagGroup>}

                {/* {!!isBot && !feedbackFooter && <div className="aims-caveats">
                    <span>Generated by AI • Internal use only</span>
                    <CampfireEnterpriseButton
                        buttonType="button"
                        size="default"
                        type="inline-link"
                        verticalOffset="clear"
                    >
                        <CampfireEnterpriseIcon className="aims-caveat-info">info</CampfireEnterpriseIcon>
                    </CampfireEnterpriseButton>
                </div>} */}

                {!!isBot && !!feedbackFooter && <div className="aims-feedback-footer">
                    <div className="aims-feedback-footer__caveats">
                        <span>Generated by AI • Internal use only</span>
                        <CampfireEnterpriseButton
                            buttonType="button"
                            size="default"
                            type="inline-link"
                            verticalOffset="clear"
                        >
                            <CampfireEnterpriseIcon className="aims-caveat-info">info</CampfireEnterpriseIcon>
                        </CampfireEnterpriseButton>
                    </div>
                    <div className="aims-feedback-actions">
                        <CampfireEnterpriseButton
                            buttonType="button"
                            size="default"
                            type="inline-link"
                            verticalOffset="clear"
                        >
                            <CampfireEnterpriseIcon className="aims-feedback-icon">
                                share
                            </CampfireEnterpriseIcon>
                        </CampfireEnterpriseButton>

                        <CampfireEnterpriseButton
                            buttonType="button"
                            size="default"
                            type="inline-link"
                            verticalOffset="clear"
                            onClick={dislikeClick}
                        >
                            <CampfireEnterpriseIcon className="aims-feedback-icon">
                                {isDisliked ? 'feedback_disliked' : 'feedback_dislike'}
                            </CampfireEnterpriseIcon>
                        </CampfireEnterpriseButton>

                        <CampfireEnterpriseButton
                            buttonType="button"
                            size="default"
                            type="inline-link"
                            verticalOffset="clear"
                            onClick={likeClick}
                        >
                            <CampfireEnterpriseIcon className="aims-feedback-icon aims-feedback-dislike-icon">
                                {isLiked ? 'feedback_liked' : 'feedback_like'}
                            </CampfireEnterpriseIcon>
                        </CampfireEnterpriseButton>

                        <CampfireEnterpriseActionMenu
                            actions={actions}
                            verticalOffset="clear"
                            menuLabel={``}
                        />
                    </div>
                </div>
                }
            </CampfireEnterpriseChatMessage >
        </div >
    )
}