import React from 'react';
import { CampfireEnterpriseText, CampfireEnterpriseButton, CampfireEnterpriseActionMenu } from '@etrade-design-language/campfire-enterprise-react';
import '../style/history.css'
import { last30Days } from '../mock-data';

const deleteAction = (action) => {
    console.log('action 🔥', action);
}

const actions = [
    {
        // iconLeft: 'delete',
        text: 'Delete',
        onclick: deleteAction
    },
    {
        // iconLeft: 'delete',
        text: 'Rename Chat',
    }
];

export default function ChatHistory({ historyLast7 }) {
    const aimsSidebar = document.getElementById('aims-sidebar');
    return (
        <div>
            <div className="aims-history-heading">
                <CampfireEnterpriseText
                    emphasis
                    tag="h2"

                >
                    Last 7 days
                </CampfireEnterpriseText>
            </div>

            <ul className='aims-history-ul' id="aims-history-ul">

                {historyLast7 && historyLast7.map((m, index) => (<li className='aims-history-li' key={`last-7-${index}`}>

                    <CampfireEnterpriseButton type="inline-link"
                        verticalOffset="clear">
                        {m}</CampfireEnterpriseButton>

                    <CampfireEnterpriseActionMenu
                        actions={actions}
                        verticalOffset="clear"
                        containerRef={aimsSidebar}
                        compact="true"
                    // menuLabel={`${m} Action menu`}
                    />
                </li>))}
            </ul>
            <div className="aims-history-heading">
                <CampfireEnterpriseText
                    emphasis
                    tag="h2"
                >
                    Last 30 days
                </CampfireEnterpriseText>
            </div>

            <ul className='aims-history-ul'>
                {last30Days.map((m, index) => (<li key={`last-30-${index}`} className='aims-history-li'>
                    <CampfireEnterpriseButton type="inline-link"
                        verticalOffset="clear">
                        {m}</CampfireEnterpriseButton>
                    <CampfireEnterpriseActionMenu
                        actions={actions}
                        verticalOffset="clear"
                        compact="true"
                        // menuLabel={`${m} Action menu`}
                        containerRef={aimsSidebar}
                    />
                </li>))}
            </ul>

        </div>
    )
}