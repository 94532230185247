import React from 'react'
import { CampfireEnterpriseRadioGroup, CampfireEnterpriseButton, CampfireEnterpriseDropdown } from '@etrade-design-language/campfire-enterprise-react';
import './upload-summarize.css';

export default function UploadSummarize({ addMessage }) {
    const radiosArray = [
        { label: 'Basic', checked: true },
        { label: 'Advanced' }
    ];

    const radioSelectHandler = e => console.log(e.detail.target.value);

    const summaryOptions = [
        { value: 'Paragraph' },
        { value: 'Bullets' },
    ];

    const wordCount = [
        { value: '2000 words' },
        { value: '5000 words' },
    ];

    return (
        <div>
            <div>
                <p className="aims-p">No problem! Please provide a few parameters for your summary first:</p>

                <div className="aims-summary-radio">
                    <CampfireEnterpriseRadioGroup
                        layout="inline"
                        name="radios"
                        radios={radiosArray}
                        verticalOffset="clear"
                        onRadioSelect={radioSelectHandler}
                    />
                </div>

            </div>

            <div className="aims-summary-dropdowns">
                <div>
                    <CampfireEnterpriseDropdown
                        label="Summary format"
                        options={summaryOptions}
                        value={summaryOptions?.[0]?.value}
                        promptText="Select"
                        size="body"
                        verticalOffset="clear"
                    />
                </div>

                <div>
                    <CampfireEnterpriseDropdown
                        label="Word count"
                        options={wordCount}
                        value={wordCount?.[0]?.value}
                        promptText="Select"
                        size="body"
                        verticalOffset="clear"
                    />
                </div>
            </div>
            <div className="aims-summary-submit">
                <CampfireEnterpriseButton
                    buttonId="button-id"
                    buttonType="button"
                    size="default"
                    type="primary"
                    verticalOffset="clear"
                    onClick={() => { addMessage({ content: 'Paragraph format, 2000 words', isBot: false }) }}
                >
                    Continue
                </CampfireEnterpriseButton>
            </div>

        </div>
    )
}