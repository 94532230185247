export const answers = [
    '🎱 It is certain',
    '🎱 Without a doubt',
    '🎱 You may rely on it',
    '🎱 Yes definitely',
    '🎱 It is decidedly so',
    '🎱 As I see it, yes',
    '🎱 Most likely',
    '🎱 Yes',
    '🎱 Outlook good',
    '🎱 Signs point to yes',
    '🎱 Neutral Answers',
    '🎱 Reply hazy try again',
    '🎱 Better not tell you now',
    '🎱 Ask again later',
    '🎱 Cannot predict now',
    '🎱 Concentrate and ask again',
    '🎱 Negative Answers',
    '🎱 Don’t count on it',
    '🎱 Outlook not so good',
    '🎱 My sources say no',
    '🎱 Very doubtful',
    '🎱 My reply is no',
];

export const taskDropdownOptions = [
    { value: 'Basic', displayValue: 'AI@MS+' },
    { value: 'Summarizer' },
    { value: 'Email Wizard' },
    { value: 'Prompt Guru' },
    { value: 'Debrief' },
    { value: 'Marketing Muse' },
];

export const taskDropdownOptions2 = [
    { value: 'Basic', displayValue: 'AI@MS+' },
    { value: 'Summarizer', displayValue: 'Summarize content' },
    { value: 'Email Wizard', displayValue: 'Write an email' },
    { value: 'Prompt Guru', displayValue: 'Write a prompt' },
    { value: 'Debrief', displayValue: 'Capture meeting notes' },
    { value: 'Marketing Muse', displayValue: 'Marketing Content' },
];

export const tasks = [
    { task: 'Summarizer', taskNameAlt: 'Summarize content', description: 'Summarize articles, financial reports, emails, and more.' },
    { task: 'Email Wizard', taskNameAlt: 'Write an email', description: 'Draft emails and customize them to your needs.' },
    { task: 'Prompt Guru', taskNameAlt: 'Write a prompt', description: 'Create structured prompts using instructions provided.' },
    { task: 'Debrief', taskNameAlt: 'Capture meeting notes', description: 'Take notes on or summarize recorded Zoom meetings.' },
    { task: 'Marketing Muse', taskNameAlt: 'Marketing Content', description: 'Create marketing content for different channels.' },
    { task: '360Pro', description: 'Enables managers to consolidate 360 - reviews and write customized year - end reviews.' },
    { task: 'Assistant', description: 'Virtual assistant Search and Ask tool tied to internal content and repositories.' },
    { task: 'Insights / Analyze', description: 'Analyze financial reports, spreadsheets and more.' },
    { task: 'Research assistant', description: ' Source and summarizes internet data.' },
    { task: 'Internal memo', description: 'Drafts memos aligned with firm standards and templates.' },
];

export const last7Days = [
    'Translate this summary into Spanish',
    'Summarize this meeting',
    'Draft an email to all clients',
    'Translate this document',
    'Analyze the data in this',
];

export const last30Days = [
    'Translate this summary into Spanish',
    'Summarize this meeting',
    'Draft an email to all clients',
    'Translate this document',
    'Analyze the data in this',
    'Translate this summary into Spanish',
    'Summarize this meeting',
    'Draft an email to all clients',
    'Translate this document',
    'Analyze the data in this',
];

export const uploads = [
    'lorem.pdf',
    '360reviews.pdf',
    'client-seminar.jpg',
    'ipsum.xls',
    'Analyzer.png'
];

