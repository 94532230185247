// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aims-task {
    margin-top: 20px;
}

.aims-task {
    margin-bottom: 16px;
}

.aims-no-matching-tasks {
    text-align: center;
    margin-top: 16px;
    color: #666666;
}`, "",{"version":3,"sources":["webpack://./src/style/tasks.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".aims-task {\n    margin-top: 20px;\n}\n\n.aims-task {\n    margin-bottom: 16px;\n}\n\n.aims-no-matching-tasks {\n    text-align: center;\n    margin-top: 16px;\n    color: #666666;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
