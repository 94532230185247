import React, { useState, useRef } from 'react';
import { CampfireEnterpriseText, CampfireEnterpriseButton, CampfireEnterpriseIcon, CampfireEnterpriseCol, CampfireEnterpriseGrid, CampfireEnterpriseModal } from '@etrade-design-language/campfire-enterprise-react';

export default function IdeasNew({ addMessage }) {
    const [showIdeas, setShowIdeas] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const ideasRef = useRef();
    const newRef = useRef();
    const toggleIdeasModal = () => setShowIdeas(prevState => !prevState);
    const toggleNewModal = () => setShowNew(prevState => !prevState);
    return (
        <>
            <CampfireEnterpriseGrid className="aims-ideas-new-wrap">
                <CampfireEnterpriseCol
                    spanXs="12" span="24" className="aims-ideas-col">
                    <div className="aims-new-ideas-heading-more">
                        {/* <CampfireEnterpriseHeader
                            headingLevel="2"
                            variant="section"
                            verticalOffset="clear"
                        >
                            Ideas to get you going
                        </CampfireEnterpriseHeader> */}

                        <h2 className="aims-ideas-heading-inner">
                            Quick ideas
                        </h2>
                        <div>
                            <CampfireEnterpriseButton
                                buttonId="button-id"
                                buttonType="button"
                                size="default"
                                type="tertiary"
                                verticalOffset="clear"
                                ref={ideasRef}
                                onButtonClick={toggleIdeasModal}
                            >
                                More <span className='sr-only'> - Ideas</span> <span aria-hidden="true">{'>'}</span>
                            </CampfireEnterpriseButton>
                        </div>
                    </div>
                    <ul className="aims-ideas-ul ideas-new-list-wrap" aria-label="Ideas">
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                buttonType="button"
                                onClick={() => addMessage({ content: 'Summarize this PDF for me in bullet points using less than 200 words', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                Summarize this PDF for me in bullet points using less than 200 words
                            </CampfireEnterpriseButton>
                        </li>
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({ content: 'Draft email informing colleagues I’ll be out of the office next week', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                Draft email informing colleagues I’ll be out of the office next week
                            </CampfireEnterpriseButton>
                        </li>
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({ content: 'Explain to me what an AI task is?', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                What is an AI workflow?
                            </CampfireEnterpriseButton>
                        </li>
                    </ul>
                </CampfireEnterpriseCol>


                <CampfireEnterpriseCol
                    spanXs="12" span="24"
                    className="aims-new-col">
                    <div className="aims-new-ideas-heading-more">
                        {/* <CampfireEnterpriseHeader
                            headingLevel="2"
                            variant="section"
                            verticalOffset="clear"
                        >
                            What's new
                        </CampfireEnterpriseHeader> */}
                        <h2 className="aims-ideas-heading-inner">
                            What's new
                        </h2>
                        <div>
                            <CampfireEnterpriseButton
                                buttonId="button-id"
                                buttonType="button"
                                size="default"
                                type="tertiary"
                                verticalOffset="clear"
                                ref={newRef}
                                onButtonClick={toggleNewModal}
                            >
                                More <span className='sr-only'> - What's new</span> <span aria-hidden="true">{'>'}</span>
                            </CampfireEnterpriseButton>
                        </div>
                    </div>
                    <div className="ideas-new-list-wrap">
                        <CampfireEnterpriseText>Version 1.0.2 (July 15, 2024)</CampfireEnterpriseText>
                        <ul className="aims-new-ul" aria-label="Version 1.0.2 (July 15, 2024)">
                            <li>New workflow: Prompt Guru helps to create prompts using instructions provided.</li>
                            <li>Minor bug fixes and UI enhancements.</li>
                        </ul>
                    </div>
                </CampfireEnterpriseCol>
            </CampfireEnterpriseGrid>
            <CampfireEnterpriseModal
                show={showIdeas}
                header="Ideas to get you going"
                size="lg"
                triggerRef={ideasRef?.current}
                onModalClose={toggleIdeasModal}>
                <>
                    <ul className="aims-ideas-ul">
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                buttonType="button"
                                onClick={() => addMessage({ content: '  Summarize this PDF for me in bullet points using less than 200 words', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                Summarize this PDF for me in bullet points using less than 200 words
                            </CampfireEnterpriseButton>
                        </li>
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({ content: 'Draft email informing colleagues I’ll be out of the office next week', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                Draft email informing colleagues I’ll be out of the office next week
                            </CampfireEnterpriseButton>
                        </li>
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({ content: 'Explain to me what an AI task is?', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                Explain to me what an AI task is?
                            </CampfireEnterpriseButton>
                        </li>
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                buttonType="button"
                                onClick={() => addMessage({ content: '  Summarize this PDF for me in bullet points using less than 200 words', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                Summarize this PDF for me in bullet points using less than 200 words
                            </CampfireEnterpriseButton>
                        </li>
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({ content: 'Draft email informing colleagues I’ll be out of the office next week', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                Draft email informing colleagues I’ll be out of the office next week
                            </CampfireEnterpriseButton>
                        </li>
                        <li className="aims-ideas-li">
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({ content: 'Explain to me what an AI task is?', isBot: false })}
                            >
                                <CampfireEnterpriseIcon>
                                    ai
                                </CampfireEnterpriseIcon>
                                Explain to me what an AI task is?
                            </CampfireEnterpriseButton>
                        </li>
                    </ul>
                </>
            </CampfireEnterpriseModal>
            <CampfireEnterpriseModal
                show={showNew}
                header="What's New"
                size="med"
                triggerRef={newRef?.current}
                onModalClose={toggleNewModal}>
                <>
                    <CampfireEnterpriseText>Version 1.0.2 (July 15, 2024)</CampfireEnterpriseText>
                    <ul className="aims-new-ul">
                        <li>New feature allowing users to lorem ipsum doalr septa nueva vorna tella septa nueva cara.</li>
                        <li>Fixed bug thatm ipsum doalr septa nueva vorna tella septa nueva cara.</li>
                    </ul>

                    <CampfireEnterpriseText>Version 1.0.1 (July 13, 2024)</CampfireEnterpriseText>
                    <ul className="aims-new-ul">
                        <li>New feature allowing users to lorem ipsum doalr septa nueva vorna tella septa nueva cara.</li>
                        <li>Fixed bug thatm ipsum doalr septa nueva vorna tella septa nueva cara.</li>
                    </ul>
                </>
            </CampfireEnterpriseModal>
        </>
    )
}









