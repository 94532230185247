import React from 'react'
import { CampfireEnterpriseText, CampfireEnterpriseCard, CampfireEnterpriseCol, CampfireEnterpriseGrid } from '@etrade-design-language/campfire-enterprise-react';
import IdeasNew from './ideas-new';
import '../style/tasks-ideas-news.css';

export default function TasksLanding({ summarizeClick, debriefClick, drafterClick, taskSelectClick, tasksDrawerOpen, isTasksOpen, addMessage, contentGroup, guruClick, museClick }) {

    return (
        <div className="aims-chat-inner">
            <div className="aims-chat-grid">
                <CampfireEnterpriseGrid>
                    <CampfireEnterpriseCol span="24">
                        <div className="aims-select-task-heading-wrap">
                            {/* <CampfireEnterpriseHeader
                              headingLevel="1"
                              variant="section"
                            >
                              Select a task, or just start typing below.
                            </CampfireEnterpriseHeader> */}
                            <h2 className="aims-select-task-heading">
                                Select a workflow to get started or type a message in the text box
                            </h2>
                        </div>
                    </CampfireEnterpriseCol>
                </CampfireEnterpriseGrid>

                <div className="aims-select-task-wrapper">
                    <CampfireEnterpriseGrid
                        verticalOffset="clear"
                    >
                        <CampfireEnterpriseCol spanXs="8" span="12" className='aims-select-col'>
                            <button className="aims-select-task-btn aims-btn-reset" onClick={summarizeClick}>
                                <CampfireEnterpriseCard className="aims-select-card-outer"
                                >
                                    <div className="aims-select-task-card">
                                        <div className="aims-select-img-wrap">
                                            <img src="summarize.svg" alt="" />
                                        </div>
                                        <div className="aims-select-text-wrap">
                                            <div>
                                                <CampfireEnterpriseText
                                                    emphasis
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-primary-txt">
                                                        {contentGroup !== 'b' && 'Summarizer'}
                                                        {contentGroup === 'b' && 'Summarize content'}
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>

                                            <div className="aims-select-secondary-txt-wrap">
                                                <CampfireEnterpriseText
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-secondary-txt">
                                                        Summarize articles, financial reports, emails, and more.
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>
                                        </div>
                                    </div>
                                </CampfireEnterpriseCard>
                            </button>
                        </CampfireEnterpriseCol>
                        <CampfireEnterpriseCol spanXs="8" span="12" className='aims-select-col'>
                            <button className="aims-select-task-btn aims-btn-reset" onClick={drafterClick}>
                                <CampfireEnterpriseCard className="aims-select-card-outer"
                                >
                                    <div className="aims-select-task-card">
                                        <div className="aims-select-img-wrap">
                                            <img src="drafter.svg" alt="" />
                                        </div>
                                        <div className="aims-select-text-wrap">
                                            <div>
                                                <CampfireEnterpriseText
                                                    emphasis
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-primary-txt">
                                                        {contentGroup !== 'b' && 'Email Wizard'}
                                                        {contentGroup === 'b' && 'Write an email'}
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>

                                            <div className="aims-select-secondary-txt-wrap">
                                                <CampfireEnterpriseText
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-secondary-txt">
                                                        Draft emails and customize them to your needs.
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>
                                        </div>
                                    </div>
                                </CampfireEnterpriseCard>
                            </button>
                        </CampfireEnterpriseCol>
                        <CampfireEnterpriseCol spanXs="8" span="12" className='aims-select-col'>
                            <button className="aims-select-task-btn aims-btn-reset" onClick={guruClick}>
                                <CampfireEnterpriseCard className="aims-select-card-outer"
                                >
                                    <div className="aims-select-task-card">
                                        <div className="aims-select-img-wrap">
                                            <img src="guru.svg" alt="" />
                                        </div>
                                        <div className="aims-select-text-wrap">
                                            <div>
                                                <CampfireEnterpriseText
                                                    emphasis
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-primary-txt">
                                                        {contentGroup !== 'b' && 'Prompt Guru'}
                                                        {contentGroup === 'b' && 'Write a prompt'}
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>

                                            <div className="aims-select-secondary-txt-wrap">
                                                <CampfireEnterpriseText
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-secondary-txt">
                                                        Create structured prompts using instructions provided.
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>
                                        </div>
                                    </div>
                                </CampfireEnterpriseCard>
                            </button>
                        </CampfireEnterpriseCol>

                        <CampfireEnterpriseCol spanXs="8" span="12" className='aims-select-col'>
                            <button className="aims-select-task-btn aims-btn-reset" onClick={debriefClick}>
                                <CampfireEnterpriseCard className="aims-select-card-outer"
                                >
                                    <div className="aims-select-task-card">
                                        <div className="aims-select-img-wrap">
                                            <img src="debrief.svg" alt="" />
                                        </div>
                                        <div className="aims-select-text-wrap">
                                            <div>
                                                <CampfireEnterpriseText
                                                    emphasis
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-primary-txt">
                                                        {contentGroup !== 'b' && 'Debrief'}
                                                        {contentGroup === 'b' && 'Capture meeting notes'}
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>

                                            <div className="aims-select-secondary-txt-wrap">
                                                <CampfireEnterpriseText
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-secondary-txt">
                                                        Take notes on or summarize recorded Zoom meetings.
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>
                                        </div>
                                    </div>
                                </CampfireEnterpriseCard>
                            </button>
                        </CampfireEnterpriseCol>

                        <CampfireEnterpriseCol spanXs="8" span="12" className='aims-select-col'>
                            <button className="aims-select-task-btn aims-btn-reset" onClick={museClick}>
                                <CampfireEnterpriseCard className="aims-select-card-outer"
                                >
                                    <div className="aims-select-task-card">
                                        <div className="aims-select-img-wrap">
                                            <img src="muse.svg" alt="" />
                                        </div>
                                        <div className="aims-select-text-wrap">
                                            <div>
                                                <CampfireEnterpriseText
                                                    emphasis
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-primary-txt">
                                                        {contentGroup !== 'b' && 'Marketing Muse'}
                                                        {contentGroup === 'b' && 'Marketing content'}
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>

                                            <div className="aims-select-secondary-txt-wrap">
                                                <CampfireEnterpriseText
                                                    verticalOffset="clear"
                                                >
                                                    <span className="aims-select-secondary-txt">
                                                        Create marketing content for different channels.
                                                    </span>
                                                </CampfireEnterpriseText>
                                            </div>
                                        </div>
                                    </div>
                                </CampfireEnterpriseCard>
                            </button>
                        </CampfireEnterpriseCol>

                        <CampfireEnterpriseCol spanXs="8" span="12" className='aims-select-col' flex justifyContent="end" alignItems="end">
                            <button className="aims-select-task-btn aims-btn-reset" onClick={() => { tasksDrawerOpen(!isTasksOpen) }}>
                                <CampfireEnterpriseCard className="aims-select-card-outer"
                                >
                                    <div className="aims-select-task-card aims-select-task-card--txt-only">
                                        <CampfireEnterpriseText
                                            emphasis
                                            verticalOffset="clear"
                                        >
                                            <span className="aims-select-primary-txt">View all</span>
                                        </CampfireEnterpriseText>
                                    </div>
                                </CampfireEnterpriseCard>
                            </button>
                        </CampfireEnterpriseCol>
                    </CampfireEnterpriseGrid>
                </div>
            </div>

            {/* ideas / what's new */}
            <IdeasNew addMessage={addMessage} />
        </div>
    )
} 