import React from 'react'
import { CampfireEnterpriseExpandCollapse, CampfireEnterpriseButton, CampfireEnterpriseIcon } from '@etrade-design-language/campfire-enterprise-react';
import './drafter.css';

export default function MidyearEmail({ addMessage }) {
    const statusUpdateHandler = e => console.log(e.detail);

    return (
        <div>

            <div className="aims-drafter-wrap">
                <p className="aims-p">No problem! Here is your email draft:</p>
                <br />
                <p className="aims-p"><b>Subject: Sick OOO</b></p>
                <p className="aims-p">Hi team,</p>
                <p className="aims-p">Unfortunately, I am not feeling too well and will not be able to come into the office today and tomorrow. My doctor told me that I have [illness], and he has advised me to rest so that I can fully recover.</p>
                <p className="aims-p">You can email me or message me on Teams if there are any urgent matters, but I have asked Name to handle my immediate tasks and deadlines during my absence.</p>
                <p className="aims-p">Please let me know if you have any questions. I am hoping to be back to the office by [Day/Date].</p>
                <p className="aims-p">Thanks so much for your understanding,,</p>
                <p className="aims-p">Anna Analyst</p>
            </div>

            <CampfireEnterpriseExpandCollapse
                expanded
                headingLevel="3"
                triggerText="Send a follow-up message"
                className="aims-followup"
                onStatusUpdate={statusUpdateHandler}
            >
                <ul className="aims-followup-ul" aria-label="Send a follow-up message">
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            buttonType="button"
                            onClick={() => addMessage({ content: 'Open email draft in Outlook', isBot: false })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Open email draft in Outlook
                        </CampfireEnterpriseButton>
                    </li>
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            onClick={() => addMessage({ content: 'Provide details on the Enhanced Performance Management (PM) Process', isBot: false })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Provide details on the Enhanced Performance Management (PM) Process
                        </CampfireEnterpriseButton>
                    </li>
                </ul>
            </CampfireEnterpriseExpandCollapse>

            <CampfireEnterpriseExpandCollapse
                headingLevel="3"
                triggerText="Sources"
                className="aims-followup"
                onStatusUpdate={statusUpdateHandler}
            >
                <ul className="aims-sources-ul" aria-label="Sources">
                    <li className="aims-sources-li">
                        <div>
                            <sup>1</sup>
                            &nbsp;
                            <CampfireEnterpriseButton
                                type="inline-link"
                                buttonType="button"
                                onClick={() => addMessage({ content: 'Draft an email to my colleague about low-carbon solutions', isBot: false })}
                            >

                                US-policy_Energy.pdf
                            </CampfireEnterpriseButton>
                            &nbsp;
                            (2022), p. 1
                        </div>
                        <div>
                            June 1, 2022
                        </div>
                    </li>
                    <li className="aims-sources-li">
                        <div>
                            <sup>2</sup>
                            &nbsp;
                            <CampfireEnterpriseButton
                                type="inline-link"
                                onClick={() => addMessage({ content: 'How does biodiversity loss impact the economy?', isBot: false })}
                            >
                                US-policy_Energy.pdf
                            </CampfireEnterpriseButton>
                            &nbsp;
                            (2022), p. 6
                        </div>
                        <div>
                            June 1, 2022
                        </div>
                    </li>
                </ul>
            </CampfireEnterpriseExpandCollapse>
        </div>
    )
}