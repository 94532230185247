import React from 'react';
import { CampfireEnterpriseButton, CampfireEnterpriseIcon } from '@etrade-design-language/campfire-enterprise-react';
import '../style/history.css'

// const deleteAction = (action) => {
//     console.log('action 🔥', action);
// }

// const actions = [
//     {
//         iconLeft: 'download',
//         text: 'Download',
//         onclick: deleteAction
//     }
// ];

export default function UploadsHistory({ uploads }) {
    // const aimsSidebar = document.getElementById('aims-sidebar');
    return (
        <div>
            <ul className='aims-history-ul'>
                {uploads?.length && uploads.map((m, index) => (<li className='aims-history-li' key={`last-7-${index}`}>
                    <div className="aims-btn-reset">
                        <CampfireEnterpriseButton type="inline-link"
                            verticalOffset="clear">
                            <CampfireEnterpriseIcon>notes</CampfireEnterpriseIcon>

                            {m}</CampfireEnterpriseButton>
                    </div>
                    {/* <CampfireEnterpriseActionMenu
                        actions={actions}
                        verticalOffset="clear"
                        compact="true"
                        containerRef={aimsSidebar}
                    // menuLabel={`${m} Action menu`}
                    /> */}
                    <CampfireEnterpriseButton
                        type="inline-link"
                        ariaLabel="Download"
                        title="Download"
                    >
                        <CampfireEnterpriseIcon>
                            download
                        </CampfireEnterpriseIcon>
                    </CampfireEnterpriseButton>
                </li>))}
            </ul>
        </div>
    )
}