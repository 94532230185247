import React from 'react'
import { CampfireEnterpriseExpandCollapse, CampfireEnterpriseButton, CampfireEnterpriseIcon } from '@etrade-design-language/campfire-enterprise-react';
import './drafter.css';

export default function Drafter({ addMessage }) {
    const statusUpdateHandler = e => console.log(e.detail);

    return (
        <div>
            <div className="aims-drafter-wrap">
                <p className="aims-p">
                    I can help you draft and send emails. To get started, please provide some more information:
                </p>

                <ul className="aims-drafter-ul">
                    <li>Who is this email for? (E.g., colleague, client)</li>
                    <li>What is the reason for the email?</li>
                    <li>What is the reason for the email? What should the tone be? (E.g., formal, casual, informative)</li>
                    <li>Any specific information that should be included?</li>
                </ul>

                <p className="aims-p">Once I have this information, I can create an initial draft for you, which you’ll be able to revise before it’s sent.</p>
            </div>

            <CampfireEnterpriseExpandCollapse
                expanded
                headingLevel="3"
                triggerText="Send a follow-up message"
                className="aims-followup"
                onStatusUpdate={statusUpdateHandler}
            >
                <ul className="aims-followup-ul" aria-label="Send a follow-up message">
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            buttonType="button"
                            onClick={() => addMessage({ content: 'Write me an email to my team explaining steps they can take to prepare for their mid-year reviews.', isBot: false, author: 'Anna Analyst' })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Write me an email to my team explaining steps they can take to prepare for their mid-year reviews.
                        </CampfireEnterpriseButton>
                    </li>
                    <li className="aims-followup-li">
                        <CampfireEnterpriseButton
                            type="inline-link"
                            onClick={() => addMessage({ content: 'Write an email to my colleagues that I am out sick today and tomorrow.', isBot: false })}
                        >
                            <CampfireEnterpriseIcon>
                                ai
                            </CampfireEnterpriseIcon>
                            Write an email to my colleagues that I am out sick today and tomorrow.
                        </CampfireEnterpriseButton>
                    </li>
                </ul>
            </CampfireEnterpriseExpandCollapse>
        </div>
    )
}