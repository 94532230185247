import React from 'react';
import { CampfireEnterpriseButton, CampfireEnterpriseTableWrapper } from '@etrade-design-language/campfire-enterprise-react';
import './debrief.css';


export default function Debrief({ addMessage }) {
    return (
        <div>
            <div>
                <p className="aims-p">I can provide meeting notes for FA-internal and home-office Zoom meetings. To get started:</p>

                <ul className="aims-debrief-ul">
                    <li>Learn how to set up and&nbsp;
                        <CampfireEnterpriseButton
                            buttonType="button"
                            size="default"
                            type="inline-link"
                            verticalOffset="clear"
                        >
                            record an upcoming Zoom meeting
                        </CampfireEnterpriseButton></li>
                    <li>OR summarize one of your past available Zoom meetings:</li>
                </ul>

                <CampfireEnterpriseTableWrapper
                    verticalOffset="md"
                >
                    <table>
                        <thead>
                            <tr>
                                <th><div className="sr-only">select</div></th>
                                <th data-align="left">Meeting name</th>
                                <th data-align="left">Date of recording</th>
                                <th data-align="left">Length of recording</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="aims-debrief-tr">
                                <td>
                                    <input id="meeting-1" type="radio" value="0" name="meeting" />
                                    <label for="meeting-1" className="aims-debrief-radio-label"></label>
                                </td>
                                <td data-align="left">Team Meeting</td>
                                <td data-align="left">6/5/2024</td>
                                <td data-align="left">00:30:00</td>
                            </tr>
                            <tr className="aims-debrief-tr">
                                <td>
                                    <input id="meeting-2" type="radio" value="0" name="meeting" />
                                    <label for="meeting-2" className="aims-debrief-radio-label"></label>
                                </td>
                                <td data-align="left">Quarterly Review</td>
                                <td data-align="left">6/4/2024</td>
                                <td data-align="left">00:30:00</td>
                            </tr>
                            <tr className="aims-debrief-tr">
                                <td>
                                    <input id="meeting-3" type="radio" value="0" name="meeting" />
                                    <label for="meeting-3" className="aims-debrief-radio-label"></label>
                                </td>
                                <td data-align="left">Committee Meeting</td>
                                <td data-align="left">6/1/2024</td>
                                <td data-align="left">00:30:00</td>
                            </tr>
                        </tbody>
                    </table>
                </CampfireEnterpriseTableWrapper>


                <div>
                    <CampfireEnterpriseButton
                        buttonId="button-id"
                        buttonType="button"
                        size="default"
                        type="tertiary"
                        verticalOffset="md"
                    >
                        View all
                    </CampfireEnterpriseButton>
                </div>
                <div className="aims-continue">
                    <div className="">
                        <CampfireEnterpriseButton
                            buttonId="button-id"
                            buttonType="button"
                            size="default"
                            type="primary"
                            onClick={() => { addMessage({ content: 'Summarize the following recording', isBot: false, files: ['Team Meeting - 6/5/2024'] }) }}
                        >
                            Continue
                        </CampfireEnterpriseButton>
                    </div>
                </div>
            </div>
        </div >
    )
}