/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CampfireEnterpriseIcon } from '@etrade-design-language/campfire-enterprise-react';
import HistoryPanel from './history';
import TasksPanel from './tasks';
import '../style/sidebar.css'

export default function Sidebar({ isDrawerOpen, isHistoryOpen, isTasksOpen, newClick, historyDrawerOpen, tasksDrawerOpen, historyLast7, uploadsHistory, debriefClick, summarizeClick, drafterClick, museClick, guruClick, contentGroup }) {
    return (
        <>
            {/* Drawer */}
            <div className="aims-sidebar" id="aims-sidebar">
                <div className="aims-sidebar-btn-wrap">
                    <button className={`aims-sidebar-btn aims-btn-reset aims-outline-inner aims-sidebar-btn--bottom-border`} onClick={newClick}>
                        <CampfireEnterpriseIcon className="aims-sidebar-icon">
                            add_item
                        </CampfireEnterpriseIcon>
                        <span>New Chat</span>
                    </button>
                    <button className={`aims-sidebar-btn aims-btn-reset aims-outline-inner ${isHistoryOpen ? 'aims-sidebar-btn--selected' : ''}`} aria-expanded={!!isHistoryOpen} onClick={() => { historyDrawerOpen(!isHistoryOpen) }}>
                        <CampfireEnterpriseIcon className="aims-sidebar-icon">
                            history
                        </CampfireEnterpriseIcon>
                        <span>History</span>
                    </button>
                    <button className={`aims-sidebar-btn aims-btn-reset aims-outline-inner ${isTasksOpen ? 'aims-sidebar-btn--selected' : ''}`} aria-expanded={!!isTasksOpen} onClick={() => { tasksDrawerOpen(!isTasksOpen) }}>
                        <CampfireEnterpriseIcon className="aims-sidebar-icon">
                            notes
                        </CampfireEnterpriseIcon>
                        <span>Workflows</span>
                    </button>
                </div>
                {!!isDrawerOpen && <div className="aims-sidebar-expanded">
                    <div className="aims-close-sidebar">
                        <button className="aims-btn-reset" aria-label={`close ${isHistoryOpen ? 'history' : 'tasks'}`}>
                            <CampfireEnterpriseIcon className="" onClick={() => historyDrawerOpen(false)}>
                                close
                            </CampfireEnterpriseIcon>
                        </button>
                    </div>
                    {!!isHistoryOpen && <HistoryPanel historyLast7={historyLast7} uploads={uploadsHistory} />}
                    {!!isTasksOpen && <TasksPanel summarizeClick={summarizeClick} debriefClick={debriefClick} drafterClick={drafterClick} museClick={museClick} guruClick={guruClick} contentGroup={contentGroup} />}
                </div>
                }
            </div>

            {/* Mobile Drawer */}
            <div className="aims-sidebar-mobile">
                <div className="aims-sidebar-mobile-btn-wrap">
                    <button className={`aims-btn-reset aims-outline-inner aims-sidebar-mobile-btn aims-sidebar-mobile-btn--bottom-border`} onClick={newClick}>
                        <CampfireEnterpriseIcon className="aims-sidebar-icon">
                            add_item
                        </CampfireEnterpriseIcon>
                        <span>New</span>
                    </button>
                    <button className={`aims-btn-reset aims-outline-inner aims-sidebar-mobile-btn ${isHistoryOpen ? 'aims-sidebar-mobile-btn--selected' : ''}`} onClick={() => { alert('History goes here 📚') }}>
                        <CampfireEnterpriseIcon className="aims-sidebar-icon">
                            history
                        </CampfireEnterpriseIcon>
                        <span>History</span>
                    </button>
                    <button className={`aims-btn-reset aims-outline-inner aims-sidebar-mobile-btn ${isTasksOpen ? 'aims-sidebar-mobile-btn--selected' : ''}`} onClick={() => { alert('Tasks go here 📝') }}>
                        <CampfireEnterpriseIcon className="aims-sidebar-icon">
                            notes
                        </CampfireEnterpriseIcon>
                        <span>Workflows</span>
                    </button>
                </div>
            </div>
        </>
    )
}



