import React, { useState } from 'react';
import { CampfireEnterpriseHeader, CampfireEnterpriseTabGroup, CampfireEnterpriseTab } from '@etrade-design-language/campfire-enterprise-react';
import '../style/history.css'
import ChatHistory from './chat-history';
import UploadsHistory from './uploads';

export default function HistoryPanel({ historyLast7, uploads }) {

    const [historyTabValue, setHistoryTabValue] = useState('chat');

    const selectHistoryTab = (tab) => {
        setHistoryTabValue(tab?.detail?.tabId);
    }

    return (
        <div>
            <CampfireEnterpriseHeader
                headingLevel="2"
                variant="section"
                verticalOffset="clear"
            >
                History
            </CampfireEnterpriseHeader>

            <CampfireEnterpriseTabGroup
                verticalOffset="clear"
                activeTabId={historyTabValue}
            >
                <CampfireEnterpriseTab tabId="chat" onTabSelected={selectHistoryTab}>Chat</CampfireEnterpriseTab>
                <CampfireEnterpriseTab tabId="uploaded" onTabSelected={selectHistoryTab}>Uploaded Files</CampfireEnterpriseTab>
            </CampfireEnterpriseTabGroup>
            <div className="aims-history-tab-contents">
                {historyTabValue === "chat" && <ChatHistory historyLast7={historyLast7} />}
                {historyTabValue === "uploaded" && <UploadsHistory uploads={uploads} />}
            </div>
        </div>
    )
}