import React, { useState } from 'react';
import { CampfireEnterpriseRadioGroup, CampfireEnterpriseFileUpload, CampfireEnterpriseButton, CampfireEnterpriseIcon, CampfireEnterpriseTableWrapper } from '@etrade-design-language/campfire-enterprise-react';
import './upload-summarize.css';

export default function Summarize({ addMessage }) {
    const [sumFiles, setSumFiles] = useState([]);

    console.log('addMessage', addMessage);

    const radiosArray = [
        { label: 'Upload docs', checked: true },
        { label: 'Copy & Paste Text' }
    ];

    const radioSelectHandler = e => console.log(e.detail.target.value);

    const fileAttachHandler = (e) => {
        const name = e?.detail?.files?.[0]?.name;
        setSumFiles([...sumFiles, name])
    };

    // const tagDismiss = (tag) => {
    //     const tagIndex = parseInt(tag.target.dataset.testid);
    //     const tempTags = [...sumFiles];
    //     tempTags.splice(tagIndex, 1)
    //     setSumFiles(tempTags);
    // };

    return (
        <div>
            <div>
                <p className="aims-p">I can assist you by providing summaries and answering queries based on uploaded files or copied content. To get started, add the content you’d like to summarize.</p>

                <div className="aims-summary-radio">
                    <CampfireEnterpriseRadioGroup
                        layout="inline"
                        name="radios"
                        radios={radiosArray}
                        verticalOffset="clear"
                        onRadioSelect={radioSelectHandler}
                    />
                </div>
            </div>

            <div className="aims-summary-upload">
                <CampfireEnterpriseFileUpload
                    verticalOffset="clear"
                    type="area"
                    multiple="true"
                    onFileChange={fileAttachHandler}
                    helpText="You may upload up to 5 PDFs"
                />
            </div>

            {/* <CampfireEnterpriseTagGroup slot="files">
                {sumFiles.map((file, index) => (<CampfireEnterpriseTag controlled dismissible status="informational" key={index} testId={index} onTagDismiss={tagDismiss}>{file}</CampfireEnterpriseTag>))}
            </CampfireEnterpriseTagGroup> */}


            {!!sumFiles?.length && (
                <CampfireEnterpriseTableWrapper
                    verticalOffset="md"
                >
                    <table>
                        <thead>
                            <tr>
                                <th data-align="left">Status</th>
                                <th data-align="left" className="aims-file-name">File name</th>
                                <th data-align="left">Size</th>
                                <th data-align="left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {sumFiles.map((file, index) => (
                                <tr className="aims-debrief-tr" key={index}>
                                    <td>
                                        <CampfireEnterpriseIcon className="aims-success">
                                            success
                                        </CampfireEnterpriseIcon>
                                    </td>
                                    <td data-align="left">{file}</td>
                                    <td data-align="left">1MB</td>
                                    <td data-align="center">

                                        <CampfireEnterpriseButton
                                            type="inline-link"
                                            buttonType="button"
                                        >
                                            <CampfireEnterpriseIcon>
                                                menu
                                            </CampfireEnterpriseIcon>
                                        </CampfireEnterpriseButton>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </CampfireEnterpriseTableWrapper>
            )}


            <div className="aims-summary-submit">
                <CampfireEnterpriseButton
                    buttonId="button-id"
                    buttonType="button"
                    size="default"
                    type="primary"
                    verticalOffset="clear"
                    onClick={() => {
                        addMessage({
                            content: 'Summarize the attached PDFs', isBot: false, files: sumFiles
                        })
                    }}
                // onClick={() => { addMessage({ content: <Summary addMessage={addMessage} />, isBot: true, author: 'Summarizer', files: sumFiles, feedbackFooter: true }); }}
                >
                    Continue
                </CampfireEnterpriseButton>
            </div>
        </div>
    )
}