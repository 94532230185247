import React from 'react'
import { CampfireEnterpriseButton, CampfireEnterpriseDropdown } from '@etrade-design-language/campfire-enterprise-react';
import './upload-summarize.css';

export default function DebriefDetails({addMessage}) {
    const summaryOptions = [
        { value: 'Paragraph' },
        { value: 'Bullets' },
    ];

    const wordCount = [
        { value: '2000 words' },
        { value: '5000 words' },
    ];

    const meetingType = [
        { value: 'Team meeting' },
        { value: 'Tax planning' },
        { value: 'Strategy' },
    ];


    return (
        <div>
            <div>
                <p className="aims-p">No problem! Please provide a few parameters for your summary first:</p>
            </div>

            <div className="aims-summary-dropdowns">
                <div>
                    <CampfireEnterpriseDropdown
                        label="Summary format"
                        options={summaryOptions}
                        value={summaryOptions[0].value}
                        promptText="Select"
                        size="body"
                        verticalOffset="clear"
                    />
                </div>

                <div>
                    <CampfireEnterpriseDropdown
                        label="Word count"
                        options={wordCount}
                        value={wordCount[0].value}
                        promptText="Select"
                        size="body"
                        verticalOffset="clear"
                    />
                </div>

                <div>
                    <CampfireEnterpriseDropdown
                        label="Meeting type"
                        options={meetingType}
                        value={meetingType[0].value}
                        promptText="Select"
                        size="body"
                        verticalOffset="clear"
                    />
                </div>
            </div>

            <div className="aims-summary-submit">
                <CampfireEnterpriseButton
                    buttonId="button-id"
                    buttonType="button"
                    size="default"
                    type="primary"
                    verticalOffset="clear"
                    onClick={() => { addMessage({ content: 'Bullets, 2000 words, Team meeting', isBot: false }) }}
                >
                    Continue
                </CampfireEnterpriseButton>
            </div>
        </div>
    )
}