import React from 'react'
import { CampfireEnterpriseButton, CampfireEnterpriseDropdown } from '@etrade-design-language/campfire-enterprise-react';
import './upload-summarize.css';

export default function Midyear({ addMessage }) {
    const toneOptions = [
        { value: 'Casual' },
        { value: 'Formal' },
    ];

    const audience = [
        { value: 'Colleague' },
        { value: 'Client' },
    ];

    const length = [
        { value: '100 words' },
        { value: '500 words' },
    ];


    return (
        <div>
            <div>
                <p className="aims-p">No problem! Please provide some more information:</p>
            </div>

            <div className="aims-summary-dropdowns">
                <div>
                    <CampfireEnterpriseDropdown
                        label="Tone"
                        options={toneOptions}
                        value={toneOptions[0].value}
                        promptText="Select"
                        size="body"
                        verticalOffset="clear"
                    />
                </div>

                <div>
                    <CampfireEnterpriseDropdown
                        label="Audience"
                        options={audience}
                        value={audience[0].value}
                        promptText="Select"
                        size="body"
                        verticalOffset="clear"
                    />
                </div>

                <div>
                    <CampfireEnterpriseDropdown
                        label="Length"
                        options={length}
                        value={length[0].value}
                        promptText="Select"
                        size="body"
                        verticalOffset="clear"
                    />
                </div>
            </div>

            <div className="aims-summary-submit">
                <CampfireEnterpriseButton
                    buttonId="button-id"
                    buttonType="button"
                    size="default"
                    type="primary"
                    verticalOffset="clear"
                    onClick={() => { addMessage({ content: '100 word email in a casual tone to my colleagues.', isBot: false }) }}
                >
                    Continue
                </CampfireEnterpriseButton>
            </div>
        </div>
    )
}